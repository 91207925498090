// extracted by mini-css-extract-plugin
export var toastCard = "toast-module--toast-card--1b430";
export var toastCardDescription = "toast-module--toast-card-description--c8580";
export var toastCardPrice = "toast-module--toast-card-price--f56da";
export var toastCardPriceTitle = "toast-module--toast-card-price-title--51287";
export var toastCardText = "toast-module--toast-card-text--71040";
export var toastCardTitle = "toast-module--toast-card-title--6c62c";
export var toastContainer = "toast-module--toast-container--d8419";
export var toastContainerWrapper = "toast-module--toast-container-wrapper--cbe58";
export var toastHr = "toast-module--toast-hr--9f0d8";
export var toastImage = "toast-module--toast-image--7abf6";
export var toastImageWrapper = "toast-module--toast-image-wrapper--4e619";