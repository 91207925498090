// extracted by mini-css-extract-plugin
export var aboutUsContainer = "about-module--about-us-container--f6e44";
export var aboutUsDescription = "about-module--about-us-description--738ee";
export var aboutUsSubTitle = "about-module--about-us-sub-title--55929";
export var aboutUsTitle = "about-module--about-us-title--413d0";
export var staffCard = "about-module--staff-card--1981c";
export var staffCardBody = "about-module--staff-card-body--a378e";
export var staffCardDescription = "about-module--staff-card-description--5c093";
export var staffCardImage = "about-module--staff-card-image--a759f";
export var staffCardImageWrapper = "about-module--staff-card-image-wrapper--5c36d";
export var staffCardInstagramIcon = "about-module--staff-card-instagram-icon--eff51";
export var staffCardLink = "about-module--staff-card-link--8b201";
export var staffCardName = "about-module--staff-card-name--4e896";
export var staffCardTitle = "about-module--staff-card-title--8319f";
export var staffContainer = "about-module--staff-container--dbd0b";