import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Schedule from './schedule'
import MovieWebm from '../assets/videos/mond-video.webm'
import MovieMp4 from '../assets/videos/mond-video.mp4'
import * as styles from '../styles/main-visual.module.css'

const MainVisual = () => {
  const breakpoints = useBreakpoint()
  const sliderSettings = {
    speed: 3000,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "5%",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    pauseOnHover: false,
  }

  const data = useStaticQuery(graphql`
    query MainVisualQuery {
      allContentfulBlogPost(
        sort: { fields: publishedDate, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            id
            title
            slug
            publishedDate(formatString: "YYYY.MM.DD")
            description {
              description
            }
            thumbnail {
              file {
                url
              }
            }
            tag {
              title
              slug
            }
          }
        }
      }
    }
  `)
  const blogPosts = data.allContentfulBlogPost.edges

  return (
    <div id="top" className={styles.mainVisualContainer}>
      <Slider {...sliderSettings}>
        <StaticImage
          className={styles.mainVisualImage}
          alt="Mond - Home Roasting Coffee Bar"
          src="../assets/images/main-visual-1.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="Mond - Home Roasting Coffee Bar"
          src="../assets/images/main-visual-2.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="Mond - Home Roasting Coffee Bar"
          src="../assets/images/main-visual-3.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="Mond - Home Roasting Coffee Bar"
          src="../assets/images/main-visual-4.jpg"
        />
        <StaticImage
          className={styles.mainVisualImage}
          alt="Mond - Home Roasting Coffee Bar"
          src="../assets/images/main-visual-5.jpg"
        />
      </Slider>
      <div className={styles.blogContainerWrapper}>
        <div className={styles.blogContainer}>
          <h2 className={styles.blogTitle}>Mond blog</h2>
          <div className={styles.blogCardWrapper}>
            {blogPosts.map(({ node: post }) => (
              <div key={post.id} className={styles.blogCard}>
                <Link to={`/blog/${post.slug}`} className={styles.blogCardLink}>
                  <div className={styles.blogCardImageWrapper}>
                    <img
                      className={styles.blogCardImage}
                      alt={post.title}
                      src={post.thumbnail.file.url}
                    />
                    {post.tag && <div className={styles.blogCardTag}>{`#${post.tag.title}`}</div>}
                  </div>
                </Link>
                <p className={styles.blogCardDate}>{post.publishedDate}</p>
                <h2 className={styles.blogCardTitle}>{post.title}</h2>
                <p className={styles.blogCardText}>{post.description.description}</p>
              </div>
            ))}
          </div>
          <Link to="/blogs" className={styles.blogButton}>ブログ記事一覧へ</Link>
        </div>
      </div>
      {/* <Schedule/> */}
      <video className={styles.video} autoPlay muted loop playsInline>
        <source src={MovieWebm} type="video/webm" />
        <source src={MovieMp4} type="video/mp4" />
      </video>
      <div className={styles.heroTitleContainer}>
        <h2 className={styles.heroTitleEn}>
          We shine on Chiyoda softly<br/>
          thru our hand-roasted coffee<br/>
          and butter toast.
        </h2>
        {breakpoints.xs || breakpoints.sm
        ?
        <>
          <h2 className={styles.heroTitleJp}>Mondはドイツ語で「月」</h2>
          <p className={styles.heroDescription}>
            世界各国の厳選したコーヒー豆を自家焙煎し<br/>
            一杯ずつハンドドリップで淹れたコーヒーと<br/>
            こだわりのバタートーストを通して、<br/>
            月明かりのようにそっと千代田の街を<br/>
            照らし続けていきたいと思っています。
          </p>
        </>
        :
        <h2 className={styles.heroTitleJp}>
          Mondはドイツ語で「月」<br/>
          <br/>
          世界各国の厳選したコーヒー豆を自家焙煎し、一杯ずつハンドドリップで淹れたコーヒーと<br/>
          こだわりのバタートーストを通して、月明かりのようにそっと千代田の街を照らし続けていきたいと思っています。
        </h2>}
      </div>
    </div>
  )
}

export default MainVisual
