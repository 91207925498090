// extracted by mini-css-extract-plugin
export var itemCard = "shop-module--item-card--aeea2";
export var itemCover = "shop-module--item-cover--673b3";
export var itemImage = "shop-module--item-image--1b35b";
export var itemImageWrapper = "shop-module--item-image-wrapper--f44ea";
export var itemLink = "shop-module--item-link--e0e95";
export var itemList = "shop-module--item-list--ffacf";
export var itemPrice = "shop-module--item-price--eed2a";
export var itemRow = "shop-module--item-row--90144";
export var itemTitle = "shop-module--item-title--07705";
export var onlineShopContainer = "shop-module--online-shop-container--e8b4b";
export var onlineShopImage = "shop-module--online-shop-image--bc303";