import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../styles/shop.module.css'

const Shop = () => {
  return (
    <div className={styles.onlineShopContainer}>
      <a href="https://reliermond.thebase.in/" target="_blank" rel="noopener noreferrer">
        <StaticImage
          className={styles.onlineShopImage}
          alt="Relier - Mondの姉妹店｜名古屋市中区新栄の雑貨店"
          src="../assets/images/online-shop.jpg"
        />
      </a>
      <div className={styles.itemList}>
        <div className={styles.itemRow}>
          <div className={styles.itemCard}>
            <a href="https://reliermond.thebase.in/items/34435257"
              className={styles.itemLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.itemImageWrapper}>
                <StaticImage
                  className={styles.itemImage}
                  alt="【フィンランド】ARABIA（アラビア）・Ruska（ルスカ） コーヒーカップ&ソーサー"
                  src="../assets/images/arabia-ruska.jpeg"
                />
              </div>
              <div className={styles.itemCover}>
                <p className={styles.itemTitle}>【フィンランド】ARABIA（アラビア）・Ruska（ルスカ） コーヒーカップ&ソーサー</p>
                <p className={styles.itemPrice}><strong>¥5,500</strong></p>
              </div>
            </a>
          </div>
          <div className={styles.itemCard}>
            <a href="https://reliermond.thebase.in/items/53443261"
              className={styles.itemLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.itemImageWrapper}>
                <StaticImage
                  className={styles.itemImage}
                  alt="【フィンランド】Arabia（アラビア）・Ruska（ルスカ）コーヒーポット"
                  src="../assets/images/arabia-pot.jpeg"
                />
              </div>
              <div className={styles.itemCover}>
                <p className={styles.itemTitle}>【フィンランド】Arabia（アラビア）・Ruska（ルスカ）コーヒーポット</p>
                <p className={styles.itemPrice}><strong>¥12,000</strong></p>
              </div>
            </a>
          </div>
          <div className={styles.itemCard}>
            <a href="https://reliermond.thebase.in/items/34397700"
              className={styles.itemLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.itemImageWrapper}>
                <StaticImage
                  className={styles.itemImage}
                  alt="【ベルギー・ヴィンテージ】BOCH（ボッホ）/ In the mood（インザムード） カップ&ソーサー①"
                  src="../assets/images/boch-inthemood.jpeg"
                />
              </div>
              <div className={styles.itemCover}>
                <p className={styles.itemTitle}>【ベルギー・ヴィンテージ】BOCH（ボッホ）/ In the mood（インザムード） カップ&ソーサー</p>
                <p className={styles.itemPrice}><strong>¥4,500</strong></p>
              </div>
            </a>
          </div>
        </div>
        <div className={styles.itemRow}>
          <div className={styles.itemCard}>
            <a href="https://reliermond.thebase.in/items/34397855"
              className={styles.itemLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.itemImageWrapper}>
                <StaticImage
                  className={styles.itemImage}
                  alt="【ベルギー・ヴィンテージ】BOCH（ボッホ）/ BERNADETTE（ベルナデッタ ） カップ&ソーサー"
                  src="../assets/images/boch-bernadette.jpeg"
                />
              </div>
              <div className={styles.itemCover}>
                <p className={styles.itemTitle}>【ベルギー・ヴィンテージ】BOCH（ボッホ）/ BERNADETTE（ベルナデッタ ） カップ&ソーサー</p>
                <p className={styles.itemPrice}><strong>¥4,500</strong></p>
              </div>
            </a>
          </div>
          <div className={styles.itemCard}>
            <a href="https://reliermond.thebase.in/items/35350851"
              className={styles.itemLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.itemImageWrapper}>
                <StaticImage
                  className={styles.itemImage}
                  alt="【フィンランド】ARABIA (アラビア) KOSMOS (コスモス)シリーズ　16cmプレート"
                  src="../assets/images/arabia-kosmos.jpeg"
                />
              </div>
              <div className={styles.itemCover}>
                <p className={styles.itemTitle}>【フィンランド】ARABIA (アラビア) KOSMOS (コスモス)シリーズ　16cmプレート</p>
                <p className={styles.itemPrice}><strong>¥3,600</strong></p>
              </div>
            </a>
          </div>
          <div className={styles.itemCard}>
            <a href="https://reliermond.thebase.in/items/46139895"
              className={styles.itemLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.itemImageWrapper}>
                <StaticImage
                  className={styles.itemImage}
                  alt="【西ドイツ】Scheurich(シューリッヒ)・70年代ヴィンテージ フラワーベース・花瓶"
                  src="../assets/images/scheurich.jpeg"
                />
              </div>
              <div className={styles.itemCover}>
                <p className={styles.itemTitle}>【西ドイツ】Scheurich(シューリッヒ)・70年代ヴィンテージ フラワーベース・花瓶</p>
                <p className={styles.itemPrice}><strong>¥12,000</strong></p>
              </div>
            </a>
          </div>
        </div>
        <div id="about"></div>
      </div>
    </div>
  )
}

export default Shop