import * as React from 'react'
import Layout from '../components/layout'
import MainVisual from '../components/main-visual'
import Coffee from '../components/coffee'
import Toast from '../components/toast'
import Menu from '../components/menu'
import Vintage from '../components/vintage'
import Shop from '../components/shop'
import About from '../components/about'
import Instagram from '../components/instagram'
import Access from '../components/access'
import Neighbours from '../components/neighbours'
import sal from 'sal.js'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true
  })
}

const IndexPage = () => {
  React.useEffect(() => {
    sal({
      threshold: 0.25,
    })
  }, [])

  return (
    <Layout isHome={true}>
      <MainVisual/>
      <Coffee/>
      <Toast/>
      <Menu/>
      <Vintage/>
      <Shop/>
      <About/>
      <Instagram/>
      <Access/>
      <Neighbours/>
    </Layout>
  )
}

export default IndexPage
