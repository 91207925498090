import * as React from 'react'
import { Link } from 'gatsby'
import * as styles from '../styles/menu.module.css'

const Menu = () => {
  return (
    <div className={styles.menuContainer}>
      <div className={styles.menuSpecialOffer}>
        <h2 className={styles.menuSpecialOfferTitle}>Special Offer</h2>
        <p className={styles.menuSpecialOfferText}>ドリンク＋トーストご注文の方</p>
        <p className={styles.menuSpecialOfferPrice}><strong>50円引き</strong></p>
      </div>
      <h2 className={styles.menuTitle}>Coffee</h2>
      <table className={styles.menuTable}>
        <thead hidden>
          <tr>
            <th>Item</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>フィルターコーヒー（ホット/アイス）</td>
            <td>
              450円
              <span className={styles.menuItemLarge}>L</span>
            </td>
          </tr>
          <tr>
            <td>エスプレッソ</td>
            <td>450円</td>
          </tr>
          <tr>
            <td>カフェ・ラテ（ホット/アイス）</td>
            <td>550円</td>
          </tr>
          <tr>
            <td>カフェ・オレ（ホット/アイス）</td>
            <td>
              500円
              <span className={styles.menuItemLarge}>L</span>
            </td>
          </tr>
          <tr>
            <td>アメリカーノ（ホット/アイス）</td>
            <td>450円</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.menuText}>
        <span className={styles.menuItemLarge} style={{margin: 0}}>L</span>：プラス100円で、<strong>ラージサイズ</strong>に変更可能です。<br/>
        コーヒー豆は常時<strong>5〜6種類</strong>をご用意しています。<br/>
        全てのコーヒーメニューは、<strong>カフェインレス</strong>でもご提供可能です。<br/>
        お気軽にお尋ねください。
      </p>
      <h2 className={styles.menuTitle}>Toast</h2>
      <table className={styles.menuTable}>
        <thead hidden>
          <tr>
            <th>Item</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>バタートースト</td>
            <td>400円</td>
          </tr>
          <tr>
            <td>小倉バタートースト　</td>
            <td>500円</td>
          </tr>
          <tr>
            <td>チーズトースト　</td>
            <td>580円</td>
          </tr>
          <tr>
            <td>ハム・チーズトースト　</td>
            <td>650円</td>
          </tr>
        </tbody>
      </table>
      <h2 className={styles.menuTitle}>Other Drinks</h2>
      <table className={styles.menuTable}>
        <thead hidden>
          <tr>
            <th>Item</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>もふもふミルク（ホット/アイス）</td>
            <td>450円</td>
          </tr>
          <tr>
            <td>信州100%りんごジュース</td>
            <td>450円</td>
          </tr>
          <tr>
            <td>季節の薬膳茶（ホット）</td>
            <td>450円＊</td>
          </tr>
          <tr>
            <td>オーガニックソーダ「GALVANINA」　</td>
            <td>530円＊</td>
          </tr>
          <tr>
            <td>オーガニックソーダ「Bottega Baci」　</td>
            <td>450円＊</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.menuText}>
        ＊印はセット対象外です<br/>
        季節の薬膳茶、オーガニックソーダの種類はお尋ねください。
      </p>
      <h2 className={styles.menuTitle}>Alcohol</h2>
      <table className={styles.menuTable}>
        <thead hidden>
          <tr>
            <th>Item</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>コーヒーカクテル（<Link to="/blog/licor43" className={styles.menuTextLink}><strong>LICOR43</strong></Link>）</td>
            <td>＋150円</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.menuText}>
        「<Link to="/blog/licor43" className={styles.menuTextLink}><strong>LICOR43</strong></Link>」は全てのコーヒーメニューにトッピングできます。<br/>
        スペイン伝統のリキュールで、<strong>バニラ・キャラメル・ハチミツ・シトラス</strong>などがブレンドされています。
      </p>
      {/* <h2 className={styles.menuTitle}>CBD</h2>
      <table className={styles.menuTable}>
        <thead hidden>
          <tr>
            <th>Item</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>シングル（10mg）　</td>
            <td>＋200円</td>
          </tr>
          <tr>
            <td>ダブル（20mg）　</td>
            <td>＋350円</td>
          </tr>
        </tbody>
      </table>
      <p className={styles.menuText}>
        「<strong>CBD</strong>」は全てのコーヒーメニューにトッピングできます。<br/>
        <strong>無味無臭</strong>の<strong>水溶性CBDパウダー</strong>を使用していますので、CBDオイルのような薬草のような匂いや味はありません。<br/>
        （当店のCBDについては<Link to="/blog/cbd-coffee" className={styles.menuTextLink}>こちら</Link>をご参照ください。）
      </p> */}
      <h2 className={styles.menuTitle}>To Go | My Bottle</h2>
      <p className={styles.menuText}>
        全てのドリンクメニューは<strong>お持ち帰り可能</strong>です。<br/>
        ホットドリンクは<strong>紙カップ</strong>、コールドドリンクは<strong>プラスチックカップ</strong>でお渡しいたしますが、<br/>
        環境保護の観点から、<strong>マイボトル</strong>をお勧めしております。<br/>
        マイボトル持参の方は、<strong>20円引き</strong>させていただきます。
      </p>
      <div className={styles.menuTicket}>
        <h2 className={styles.menuTicketTitle}>コーヒーチケット</h2>
        <p className={styles.menuTicketText}>4,500円｜10杯分の料金で11枚</p>
        <h2 className={styles.menuTicketTitle}>ポイントカード</h2>
        <p className={styles.menuTicketText}>10杯で1杯無料｜LINEまたは紙</p>
      </div>
    </div>
  )
}

export default Menu