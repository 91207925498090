import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/coffee.module.css'

const Coffee = () => {
  const breakpoints = useBreakpoint()

  return (
    <div className={styles.coffeeContainerWrapper}>
      <div className={styles.coffeeContainer}>
        <div className={styles.coffeeCard}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          {breakpoints.xs || breakpoints.sm
          ?
          <p className={styles.coffeeText}>
            Mondでは、<br/>
            <strong>スペシャルティコーヒー</strong>と呼ばれる<br/>
            生産から精製、流通までが明確であり、<br/>
            自然環境を尊重し、<br/>
            <strong>フェアトレード</strong>で生産されていること<br/>
            そして<strong>高品質</strong>のコーヒー豆のみを<br/>
            セレクトしています。<br/>
            <br/>
            様々な国のコーヒー豆を<br/>
            特徴に合わせて<strong>自家焙煎</strong>しており<br/>
            常時<strong>5〜6種類</strong>ご用意しています。
          </p>
          :
          <p className={styles.coffeeText}>
            Mondでは、<strong>スペシャルティコーヒー</strong>と呼ばれる<br/>
            生産から精製、流通までが明確であり、<br/>
            自然環境を尊重し、<strong>フェアトレード</strong>で生産されていること<br/>
            そして<strong>高品質</strong>のコーヒー豆のみをセレクトしています。<br/>
            <br/>
            <br/>
            様々な国のコーヒー豆を<br/>
            特徴に合わせて<strong>自家焙煎</strong>しており<br/>
            常時<strong>5〜6種類</strong>ご用意しています。
          </p>}
        </div>
        <StaticImage
          className={styles.coffeeImage}
          alt="スペシャルティコーヒー"
          src="../assets/images/beans.jpg"
        />
      </div>
      <div className={styles.dripContainer}>
        <StaticImage
          className={styles.coffeeImage}
          alt="焼きたて・挽きたて・淹れたて"
          src="../assets/images/dripping.jpg"
        />
        <div className={styles.dripCard}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <h2 className={styles.dripTitle}>焼きたて・挽きたて・淹れたて</h2>
          <p className={styles.dripText}>
            焼きたてのコーヒー豆を<br/>
            ご注文いただいてから挽き<br/>
            <br/>
            一杯ずつ淹れています。<br/>
            <br/>
            ドリップの際に豆が膨らむのは<br/>
            フレッシュな証拠です。
          </p>
        </div>
      </div>
    </div>
  )
}

export default Coffee