import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/schedule.module.css'

const Schedule = () => {
  const breakpoints = useBreakpoint()

  return (
    <div id="schedule" className={styles.scheduleContainer}>
      <h2 className={styles.scheduleTitle}>Event Schedule</h2>
      <div className={styles.scheduleCardWrapper}>
        <div className={styles.scheduleCard}>
          <div className={styles.scheduleCardImageWrapper}>
            <Link to="/workshop/mbira">
              <StaticImage
                className={styles.scheduleCardImage}
                alt="Workshop - ジンバブエ楽器「ンビラ」"
                src="../assets/images/thumbnail-mbira.jpg"
              />
            </Link>
          </div>
          <div className={styles.scheduleCardTitle}>
            <span className={styles.scheduleCardTag}>Workshop</span>
            <p className={styles.scheduleCardDate}>4月9日(土) 14:00〜16:00</p>
            <h2 className={styles.scheduleCardName}>
              ジンバブエ楽器「ンビラ」
            </h2>
          </div>
        </div>
        {breakpoints.xs || breakpoints.sm || breakpoints.md
          ? <hr className={styles.scheduleCardHr}/> : null
        }
        <div className={styles.scheduleCard}>
          <div className={styles.scheduleCardImageWrapper}>
            <Link to="/workshop/rose-window">
              <StaticImage
                className={styles.scheduleCardImage}
                alt="Workshop - ローズウィンドウ"
                src="../assets/images/thumbnail-rosewindow.jpg"
              />
            </Link>
          </div>
          <div className={styles.scheduleCardTitle}>
            <span className={styles.scheduleCardTag}>Workshop</span>
            <p className={styles.scheduleCardDate}>4月10日(日) 14:00〜16:00</p>
            <h2 className={styles.scheduleCardName}>
              ローズウィンドウ
            </h2>
          </div>
        </div>
        {breakpoints.xs || breakpoints.sm || breakpoints.md
          ? <hr className={styles.scheduleCardHr}/> : null
        }
        <div className={styles.scheduleCard}>
          <div className={styles.scheduleCardImageWrapper}>
            <Link to="/parlor#happy-eco">
              <StaticImage
                className={styles.scheduleCardImage}
                alt="Parlor - ハッピーにエコる環境講座〜食と気候変動〜"
                src="../assets/images/thumbnail-happy-eco.jpg"
              />
            </Link>
          </div>
          <div className={styles.scheduleCardTitle}>
            <span className={styles.scheduleCardTag}>Parlor</span>
            <p className={styles.scheduleCardDate}>4月24日(日) 14:00〜15:50</p>
            <h2 className={styles.scheduleCardName}>
              ハッピーにエコる環境講座〜食と気候変動〜
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Schedule
