// extracted by mini-css-extract-plugin
export var blogButton = "main-visual-module--blog-button--aaa9a";
export var blogCard = "main-visual-module--blog-card--fa17a";
export var blogCardDate = "main-visual-module--blog-card-date--c32ec";
export var blogCardImage = "main-visual-module--blog-card-image--c32ce";
export var blogCardImageWrapper = "main-visual-module--blog-card-image-wrapper--ea5c8";
export var blogCardLink = "main-visual-module--blog-card-link--43a0a";
export var blogCardTag = "main-visual-module--blog-card-tag--518da";
export var blogCardText = "main-visual-module--blog-card-text--38d31";
export var blogCardTitle = "main-visual-module--blog-card-title--d4dc4";
export var blogCardWrapper = "main-visual-module--blog-card-wrapper--4390c";
export var blogContainer = "main-visual-module--blog-container--2ce5f";
export var blogContainerWrapper = "main-visual-module--blog-container-wrapper--52358";
export var blogTitle = "main-visual-module--blog-title--d292c";
export var heroDescription = "main-visual-module--hero-description--9c780";
export var heroTitleContainer = "main-visual-module--hero-title-container--5e7ab";
export var heroTitleEn = "main-visual-module--hero-title-en--263bb";
export var heroTitleJp = "main-visual-module--hero-title-jp--c2651";
export var mainVisualContainer = "main-visual-module--main-visual-container--8309a";
export var mainVisualImage = "main-visual-module--main-visual-image--c173f";
export var video = "main-visual-module--video--b1a6f";