import * as React from 'react'
import Slider from 'react-slick'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import chiyodaMap from '../assets/images/chiyodamap.pdf'
import * as styles from '../styles/neighbours.module.css'

const Neighbours = () => {
  const breakpoints = useBreakpoint()
  const settings = {
    speed: 3000,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: breakpoints.xs || breakpoints.sm || breakpoints.md ? "10%" : "30%",
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    pauseOnHover: false,
  }

  return (
    <div className={styles.neighboursContainer}>
      <h2 className={styles.neighboursTitle}>CHIYODA Neighbours</h2>
      <div className={styles.neighboursSlideWrapper}>
        <Slider {...settings}>
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-1.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-2.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-3.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-4.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-5.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-6.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-7.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-8.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-9.jpg"
          />
          <StaticImage
            className={styles.neighboursImage}
            alt="千代田マップ"
            src="../assets/images/chiyoda-map-10.jpg"
          />
        </Slider>
      </div>
      <a className={styles.chiyodaMapButton} href={chiyodaMap} download>
        Download CHIYODA map
      </a>
    </div>
  )
}

export default Neighbours