import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../styles/vintage.module.css'

const Vintage = () => {
  return (
    <>
      <>
        <div className={styles.bochImageWrapper}>
          <StaticImage
            className={styles.bochImage}
            alt="BOCH - ベルギーのヴィンテージカップ"
            src="../assets/images/boch.jpg"
          />
        </div>
      </>
      <div className={styles.vintageContainer}>
        <div className={styles.vintageCupContainer}>
          <StaticImage
            alt="BOCHのカップ"
            src="../assets/images/boch-cup.jpg"
          />
          <div className={styles.vintageCupCard}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <h2 className={styles.vintageCupCardTitle}>Vintage Cups</h2>
            <p className={styles.vintageCupCardText}>from Europe</p>
            <p className={styles.vintageCupCardDescription}>
              Mondでは、1960〜70年代に作られた<br/>
              ヨーロッパの<strong>ヴィンテージカップ</strong>を使用<br/>
              <br/>
              販売は姉妹店「<strong>Relier（ルリエ）</strong>」<br/>
              または、<strong>オンラインショップ</strong>にて
            </p>
            <a className={styles.vintageCupCardButton}
              href="https://reliermond.thebase.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Discover
            </a>
          </div>
        </div>
      </div>
      <div id="shop"></div>
    </>
  )
}

export default Vintage