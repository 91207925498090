import * as React from 'react'
import * as styles from '../styles/access.module.css'

const Access = () => {
  return (
    <div className={styles.accessContainerWrapper}>
      <hr className={styles.accessHr}/>
      <div className={styles.accessContainer}>
        <h2 className={styles.accessTitle}>Access / Contact us</h2>
        <div className={styles.accessRow}>
          <p className={styles.accessAddress}>
            〒460-0012<br/>
            名古屋市中区千代田3-6-19<br/>
            <br/>
            JR・地下鉄「鶴舞」駅より徒歩約5分<br/>
            <br/>
            [ 営業時間 ]　10:00 〜 18:00<br/>
            [ 定休日 ]　毎週水曜 / 第1・第3木曜<br/>
            <br/>
            052-750-5188<br/>
            mond14@icloud.com<br/>
            <br/>
          </p>
          <iframe
            title="Map of Mond"
            className={styles.accessMap}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.0438162737014!2d136.91048161524458!3d35.155527980320244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600370b9d8fc81c7%3A0xb331f4393b8eefee!2z44CSNDYwLTAwMTIg5oSb55-l55yM5ZCN5Y-k5bGL5biC5Lit5Yy65Y2D5Luj55Sw77yT5LiB55uu77yW4oiS77yR77yZ!5e0!3m2!1sja!2sjp!4v1513257062851"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <div id="chiyodamap"></div>
      </div>
    </div>
  )
}

export default Access