import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/about.module.css'

const About = () => {
  const breakpoints = useBreakpoint()

  return (
    <>
      <div className={styles.aboutUsContainer}>
        <h2 className={styles.aboutUsSubTitle}>About us</h2>
        <h2 className={styles.aboutUsTitle}>千代田の街をそっと照らす</h2>
        <p className={styles.aboutUsDescription}>
          2014年、名古屋市中区新栄で雑貨屋としてスタートした「Mond (モーント)」は、{breakpoints.xs || breakpoints.sm || breakpoints.md ? null : <br/>}
          2015年に現在の名古屋市中区千代田に移転し、コーヒースタンド併設の雑貨屋として営業してきました。<br/>
          <br/>
          千代田の街の「水飲み場」のような、ホッと一息つけるお店を目指し、{breakpoints.xs || breakpoints.sm || breakpoints.md ? null : <br/>}
          お子様連れの方から、ご年配の方まで幅広いお客様にご利用いただき、おかげさまで7周年を迎えることができました。<br/>
          <br/>
          そして2021年11月、自家焙煎コーヒーとバタートースト専門店として、リニューアルオープン！<br/>
          席数やメニューを増やして、これからも居心地の良い空間創りを目指していきたいと思います。
        </p>
      </div>
      <div className={styles.staffContainer}>
        <div className={styles.staffCard}>
          <div className={styles.staffCardImageWrapper}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.staffCardImage}
              alt="Staff - 店長 / 焙煎担当 Master"
              src="../assets/images/staff-master.png"
            />
          </div>
          <div className={styles.staffCardBody}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <h2 className={styles.staffCardName}>
              Master
              <StaticImage
                className={styles.staffCardInstagramIcon}
                alt="Instagram Icon"
                src="../assets/images/logo-instagram.svg"
              />
              <a href="https://www.instagram.com/mond_master/" className={styles.staffCardLink} target="_blank" rel="noopener noreferrer">
                @mond_master
              </a>
            </h2>
            <p className={styles.staffCardTitle}>
              店長 / 焙煎担当
            </p>
            <p className={styles.staffCardDescription}>
              Mondを始める前は時計店を営んでいました。<br/>
              定年退職を機に、昔から好きだった<strong>コーヒーとじっくり向かい合ってみたい</strong>、そう思ったんです。
            </p>
            <p className={styles.staffCardDescription}>
              コーヒーはやはり、<strong>一番美味しいタイミングで飲んでいただきたい</strong>ので、毎日少量ずつ焙煎しています。<br/>
              フレッシュなコーヒーが一番美味しいですからね。
            </p>
          </div>
        </div>
        <div className={styles.staffCard}>
          <div className={styles.staffCardImageWrapper}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.staffCardImage}
              alt="Staff - ママ / おはなし担当 Mama"
              src="../assets/images/staff-mama.png"
            />
          </div>
          <div className={styles.staffCardBody}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <h2 className={styles.staffCardName}>
              Mama
            </h2>
            <p className={styles.staffCardTitle}>
              ママ / おはなし担当
            </p>
            <p className={styles.staffCardDescription}>
              いつの間にか、みなさんのママになりました笑<br/>
              「小倉バタートースト」の<strong>自家製あんこ</strong>を作っています。
            </p>
            <p className={styles.staffCardDescription}>
              昔から<strong>アンティークやヴィンテージが好き</strong>なので、Mondのカップで歴史を感じながら、ゆっくり過ごしていただけたら嬉しいです。<br/>
              みなさんとお話できるのを楽しみにしています。
            </p>
          </div>
        </div>
        <div className={styles.staffCard}>
          <div className={styles.staffCardImageWrapper}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.staffCardImage}
              alt="Staff - オーナー / 買い付け担当 Ami"
              src="../assets/images/staff-ami.png"
            />
          </div>
          <div className={styles.staffCardBody}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <h2 className={styles.staffCardName}>
              Ami
              <StaticImage
                className={styles.staffCardInstagramIcon}
                alt="Instagram Icon"
                src="../assets/images/logo-instagram.svg"
              />
              <a href="https://www.instagram.com/mond_nagoya/" className={styles.staffCardLink} target="_blank" rel="noopener noreferrer">
                @mond_nagoya
              </a>
            </h2>
            <p className={styles.staffCardTitle}>
              オーナー / 買い付け担当
            </p>
            <p className={styles.staffCardDescription}>
              お散歩中に一目惚れした、もともと床屋だった店内を自分達で改装し、Mondを立ち上げました。
            </p>
            <p className={styles.staffCardDescription}>
              私自身は2019年にオランダへ移住し、現在は東欧の<strong>ジョージア在住</strong>。<br/>
              現地で雑貨や食器などの買い付けを行い、店舗運営やイベント企画を両親と連携して行っています。
            </p>
            <p className={styles.staffCardDescription}>
              嬉しいことがあった時もうまくいかない時も、生活の中に「<strong>ちょっとMondに寄ろうかな</strong>」があったら嬉しいです。
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default About