// extracted by mini-css-extract-plugin
export var menuContainer = "menu-module--menu-container--2bd64";
export var menuItemLarge = "menu-module--menu-item-large--7b32f";
export var menuSpecialOffer = "menu-module--menu-special-offer--b870d";
export var menuSpecialOfferPrice = "menu-module--menu-special-offer-price--d6599";
export var menuSpecialOfferText = "menu-module--menu-special-offer-text--5736d";
export var menuSpecialOfferTitle = "menu-module--menu-special-offer-title--8c450";
export var menuTable = "menu-module--menu-table--9e2a8";
export var menuText = "menu-module--menu-text--87964";
export var menuTextLink = "menu-module--menu-text-link--c9ab1";
export var menuTicket = "menu-module--menu-ticket--cc0f5";
export var menuTicketText = "menu-module--menu-ticket-text--4b5ce";
export var menuTicketTitle = "menu-module--menu-ticket-title--53b35";
export var menuTitle = "menu-module--menu-title--3b35b";