import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../styles/instagram.module.css'

const Instagram = () => {
  return (
    <div className={styles.instaContainer}>
      <div className={styles.instaTitleWrapper}>
        <h2 className={styles.instaTitle}>Follow us on</h2>
      </div>
      <div className={styles.instaImageRow}>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta1.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta2.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta3.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta4.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta5.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta6.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta7.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta8.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta9.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta10.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta11.jpg"
          />
        </div>
        <div className={styles.instaImage}
          data-sal="fade"
          data-sal-duration="2000"
          data-sal-easing="ease-out-cubic"
        >
          <StaticImage
            alt="Follow us on Instagram"
            src="../assets/images/insta12.jpg"
          />
        </div>
      </div>
      <div className={styles.snsIcons}>
        <a href="https://www.instagram.com/mond_nagoya/" target="_blank" rel="noopener noreferrer">
          <StaticImage
            className={styles.snsIcon}
            alt="Follow us on Instagram"
            src="../assets/images/logo-instagram.svg"
          />
        </a>
        <a href="https://www.facebook.com/mond2014" target="_blank" rel="noopener noreferrer">
          <StaticImage
            className={styles.snsIcon}
            alt="Follow us on Facebook"
            src="../assets/images/logo-facebook.svg"
          />
        </a>
        <a href="https://lin.ee/oekwnCy" target="_blank" rel="noopener noreferrer">
          <StaticImage
            className={styles.snsIcon}
            alt="Follow us on LINE"
            src="../assets/images/logo-line.svg"
          />
        </a>
      </div>
      <div id="access"></div>
    </div>
  )
}

export default Instagram