import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from '../styles/toast.module.css'

const Toast = () => {
  const breakpoints = useBreakpoint()

  return (
    <div className={styles.toastContainerWrapper}>
      <div className={styles.toastContainer}>
        <div className={styles.toastImageWrapper}>
          <StaticImage
            className={styles.toastImage}
            alt="バタートースト"
            src="../assets/images/toast.jpg"
          />
        </div>
        <div className={styles.toastCard}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <h2 className={styles.toastCardTitle}>Butter Toast</h2>
          <p className={styles.toastCardText}>バタートースト</p>
          <hr className={styles.toastHr}/>
          <p className={styles.toastCardDescription}>
            名古屋鶴舞の人気店「<strong>SURIPU</strong>」の<br/>
            食パンに、老舗「<strong>鈴豊乳業</strong>」のバターを<br/>
            たっぷりと乗せ、感動のトースター<br/>
            「<strong>BULMUDA</strong>（バルミューダ）」<br/>
            でじっくりと焼き上げました。<br/>
            <br/>
            バターの風味が染み込み、<br/>
            外は<strong>サクサク</strong>、中は<strong>もっちり</strong>！<br/>
          </p>
          <div className={styles.toastCardPrice}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-delay="300"
            data-sal-easing="ease-out-cubic"
          >
            {breakpoints.xs || breakpoints.sm || breakpoints.md
            ?
            <h2 className={styles.toastCardPriceTitle}>バタートースト<br/>400円</h2>
            :
            <h2 className={styles.toastCardPriceTitle}>バタートースト　400円</h2>}
          </div>
        </div>
      </div>
      <div className={styles.toastContainer}>
        <div className={styles.toastImageWrapper}>
          <StaticImage
            className={styles.toastImage}
            alt="バタートースト with 自家製あんこ"
            src="../assets/images/ogura-toast.jpg"
          />
        </div>
        <div className={styles.toastCard}
          data-sal="slide-up"
          data-sal-duration="1000"
          data-sal-easing="ease-out-cubic"
        >
          <h2 className={styles.toastCardTitle}>Ogura Toast</h2>
          <p className={styles.toastCardText}>バタートースト with 自家製あんこ</p>
          <hr className={styles.toastHr}/>
          <p className={styles.toastCardDescription}>
            名古屋名物「<strong>小倉トースト</strong>」<br/>
            <strong>国産小豆</strong>を<strong>約3時間</strong>、丁寧に煮込んだ、<br/>
            甘さ控えめの<strong>自家製あんこ</strong>をトッピング！<br/>
            <br/>
            バターとあんこの絶妙な組み合わせを<br/>
            お楽しみください！<br/>
            <br/>
            *トーストとは別でご用意します。
          </p>
          <div className={styles.toastCardPrice}
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-delay="300"
            data-sal-easing="ease-out-cubic"
          >
            {breakpoints.xs || breakpoints.sm || breakpoints.md
            ?
            <h2 className={styles.toastCardPriceTitle}>小倉バタートースト<br/>500円</h2>
            :
            <h2 className={styles.toastCardPriceTitle}>小倉バタートースト　500円</h2>}
          </div>
          <div id="menu"></div>
        </div>
      </div>
    </div>
  )
}

export default Toast